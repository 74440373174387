import * as React from "react";
import { CollectionGridProps } from "./typings";
import {
  IncentivesCollectionGrid as RetailIncentivesCollectionGrid,
  CollectionType,
  CollectionItemClickthrough,
} from "@shared-ui/retail-incentives-collections";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const CollectionLodgingGrid = withStores("context")(
  observer((props: CollectionGridProps) => {
    const {
      context: { searchContext },
      templateComponent,
    } = props;

    const {
      collectionId,
      collectionItemClickthrough = CollectionItemClickthrough.PINNED_SRP,
      collectionItemsPageSize,
      collectionItemsMaxPageSize,
      hideCollectionHeaderTitle,
      hideCollectionHeaderDescription,
      hideCollectionSummary,
      hideCollectionHeaderSubtitle,
      hideTermsAndConditions,
    } = templateComponent.config;

    /**
     * Generate placement input data for CollectionTiles
     * @see https://github.expedia.biz/eg-incentives/incentives-docs/blob/master/docs/dive/placements.md
     */

    const inputData = {
      collectionId,
      collectionItemClickthrough,
      pageContext: {
        propertySearch: { primary: { destination: { regionId: searchContext?.location?.id?.toString() }, rooms: [] } },
      },
      paginationContext: {
        pageSize: collectionItemsPageSize,
        maxPageSize: collectionItemsMaxPageSize,
      },
      expectedCollectionType: CollectionType.LODGING,
    };
    /* istanbul ignore next */
    const hideAttributes = {
      hideHeaderTitle: hideCollectionHeaderTitle ?? false,
      hideHeaderSubtitle: hideCollectionHeaderSubtitle ?? false,
      hideHeaderDescription: hideCollectionHeaderDescription ?? false,
      hideSummary: hideCollectionSummary ?? false,
      hideTermsAndConditions: hideTermsAndConditions ?? false,
    };

    return (
      <EGDSSpacing margin={{ blockstart: "six" }}>
        <div>
          <RetailIncentivesCollectionGrid inputs={inputData} {...hideAttributes} />
        </div>
      </EGDSSpacing>
    );
  })
);

export default CollectionLodgingGrid;
